import GoogleMapComponent from '@wix/thunderbolt-elements/src/components/GoogleMap/viewer/GoogleMap';


const GoogleMap = {
  component: GoogleMapComponent
};


export const components = {
  ['GoogleMap']: GoogleMap
};


// temporary export
export const version = "1.0.0"
